import request from '@/utils/request'

const statisticsApi = {
    house: '/api/v1/house/query',
    forDay: '/api/v1/developerStatistics/findStatisticsForToday', // 每日实时数据
    OrderDay: '/api/v1/developerStatistics/statisticsOrderDay', // 每日销售统计
    statisticsReportOrderType: '/api/v1/developerStatistics/statisticsReportOrderType', // 认购签约单相关统计
    statisticsTask: '/api/v1/developerStatistics/statisticsTask', // 开发商任务相关统计
    findBrokerByDeveloper: '/api/v1/developerStatistics/findBrokerByDeveloper' // 已接任务经纪人统计
}

/**
 * 
 * @param param { developerId(*): '', houseId(*): '' }
 * @returns {*}
 */
export function findDeveloperHouse(param) {
    let url = statisticsApi.house
    if (param) {
        url += '?' + Object.keys(param).map(item => `${item}=${param[item]}`).join('&')
    }
    return request({
        url,
        method: 'get'
    })
}

/**
 * 
 * @param param { developerId(*): '', houseId(*): '' }
 * @returns {*}
 */
export function findStatisticsForToday(param) {
    let url = statisticsApi.forDay
    if (param) {
        url += '?' + Object.keys(param).map(item => `${item}=${param[item]}`).join('&')
    }
    return request({
        url,
        method: 'get'
    })
}

/**
 * 
 * @param param { developerId(*): '', houseId(*): '', num(int): '' }
 * @returns {*}
 */
export function statisticsOrderDay(param) {
    let url = statisticsApi.OrderDay
    if (param) {
        url += '?' + Object.keys(param).map(item => `${item}=${param[item]}`).join('&')
    }
    return request({
        url,
        method: 'get'
    })
}

/**
 * 
 * @param param { developerId(*): '', houseId(*): '' }
 * @returns {*}
 */
export function statisticsReportOrderType(param) {
    let url = statisticsApi.statisticsReportOrderType
    if (param) {
        url += '?' + Object.keys(param).map(item => `${item}=${param[item]}`).join('&')
    }
    return request({
        url,
        method: 'get'
    })
}

/**
 * 
 * @param param { developerId(*): '', houseId(*): '' }
 * @returns {*}
 */
export function statisticsTask(param) {
    let url = statisticsApi.statisticsTask
    if (param) {
        url += '?' + Object.keys(param).map(item => `${item}=${param[item]}`).join('&')
    }
    return request({
        url,
        method: 'get'
    })
}

/**
 * 
 * @param param { developerId(*): '', houseId(): '' }
 * @returns {*}
 */
export function findBrokerByDeveloper(data) {
    return request({
        url: statisticsApi.findBrokerByDeveloper,
        method: 'post',
        data
    })
}
