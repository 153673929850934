export const ACCESS_TOKEN = 'Developer-Access-Token'

export const SIDEBAR_TYPE = 'developer_sidebar_type'
export const TOGGLE_MOBILE_TYPE = 'developer_is_mobile'
export const TOGGLE_NAV_THEME = 'developer_nav_theme'
export const TOGGLE_LAYOUT = 'developer_layout'
export const TOGGLE_FIXED_HEADER = 'developer_fixed_header'
export const TOGGLE_FIXED_SIDEBAR = 'developer_fixed_sidebar'
export const TOGGLE_CONTENT_WIDTH = 'developer_content_width'
export const TOGGLE_HIDE_HEADER = 'developer_auto_hide_header'
export const TOGGLE_COLOR = 'developer_color'
export const TOGGLE_WEAK = 'developer_weak'
export const TOGGLE_MULTI_TAB = 'developer_multi_tab'
export const APP_LANGUAGE = 'developer_app_language'

export const APP_CITY = 'developer_app_city'
export const APP_SERIAL = 'developer_app_serial'
export const APP_HOST = 'developer_app_host'
export const APP_HOUSE = 'developer_app_house'
export const APP_HOUSEID = 'developer_app_houseId'

export const CONTENT_WIDTH_TYPE = {
  Fluid: 'Fluid',
  Fixed: 'Fixed'
}

export const NAV_THEME = {
  LIGHT: 'light',
  DARK: 'dark'
}
