export default {
  dparamFormat(parameter, param = {}) {
    const pagination = {
        page: parameter.pageNo,
        page_size: parameter.pageSize
    }
    Object.assign(param, pagination)
    return param
  },
  dresFormat(res) {
    if (res.success) {
      const data = res.data
      const len = data.length
      if (len === 0) {
        const result = {
          data: [],
          pageNo: 0,
          pageSize: 10,
          totalCount: 0,
          totalPages: 1
        }
        return result
      } else {
        const result = {
          data: data.data || data,
          pageNo: data.current_page - 1 || 0,
          pageSize: data.page_size || 10,
          totalCount: data.count || len,
          totalPages: data.total_page || 1
        }
        return result
      }
    } else {
      const result = {
          data: [],
          pageNo: 0,
          pageSize: 10,
          totalCount: 0,
          totalPages: 1
      }
      this.notiError('获取数据', '获取失败')
      return result
    }
  }
}
