<template>
  <global-footer class="footer custom-render">
    <template v-slot:links>
      <!-- <a href="https://www.github.com/vueComponent/pro-layout" target="_blank">帮助</a>
      <a href="https://www.github.com/vueComponent/ant-design-vue-pro" target="_blank">隐私</a>
      <a href="https://www.github.com/sendya/" target="_blank">条款</a> -->
      <a href="#">帮助</a>
      <a href="#">隐私</a>
      <a href="#">条款</a>
    </template>
    <template v-slot:copyright>
      <!-- <a class="footer-link" href="https://github.com/vueComponent" target="_blank">copyright&copy;魔西科技出品</a> -->
      <a class="footer-link" href="#">copyright&copy;魔西科技出品</a>
    </template>
  </global-footer>
</template>

<script>
import { GlobalFooter } from '@ant-design-vue/pro-layout'

export default {
  name: 'ProGlobalFooter',
  components: {
    GlobalFooter
  }
}
</script>
