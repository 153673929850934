<template>
  <div ref="map" class="map-house" :confirmLoading="true"></div>
</template>

<script>
import { VUE_APP_MAP_TILELAYER_URL, mapUrl } from '@/common/const'
import { houseGet } from '@/api/houses'
const L = window.GMap
export default {
  name: 'AppMap',
  props: {
    location: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      map: null,
      lng: null,
      lat: null,
      address: null,
      house: null,
      houseId: null,
      houseLink: null,
      noticeTitle: '地图位置'
    }
  },
  mounted() {
    this.getHouseInfo(this.houseId, res => {
      this.loadMap(this.$refs.map, res.reverse())
    })
  },
  created() {
    this.houseId = this.$route.query.id
    this.houseLink = this.$route.query.link
  },
  watch: {
    location(newVal, oldVal) {
      if (newVal !== '') {
        const locationArr = newVal.split(',')
        const lng = locationArr[0]
        const lat = locationArr[1]
        this.panTo(lng, lat)
      } else {
        console.log('null')
      }
    }
  },
  methods: {
    getHouseInfo(id, cb = () => {}) {
      houseGet(id)
        .then(res => {
          this.house = res
          if (res.longitude && res.latitude) {
            const newArr = [res.longitude, res.latitude]
            cb(newArr)
          } else {
            const newArr = [106.577026, 29.557156]
            cb(newArr)
          }
        })
    },
    loadMap(ref, locationArr = [29.557156, 106.577026], cb = () => {}) {
      this.map = L.map(ref, {
        center: locationArr,
        zoom: 18,
        minZoom: 3,
        maxZoom: 18,
        crs: L.CRS.EPSG3857,
        maxBounds: [
          [-90, -180],
          [90, 180]
        ],
        renderer: L.canvas()
      })
      // 加载一个QuadServer栅格切片服务作为图层

      this.map.whenReady(() => {
        // 加载背景图层
        L.tileLayer(VUE_APP_MAP_TILELAYER_URL, { subdomains: '1234' }).addTo(this.map)
      })
      this.map.on('click', this.clickMap)
      this.addMarker(locationArr)
      // 拖拽地图
      this.map.on('mouseup', this.dragOver)
    },
    panTo(lng, lat) {
      const newCenter = [lat, lng]
      this.map.setView(newCenter, 18)
      this.addMarker(newCenter)
    },
    dragOver(e) {
      const center = this.map.getCenter()
      const lng = center.lng
      const lat = center.lat
      this.lng = lng
      this.lat = lat
      // this.getAddress(lng, lat, res => {
      //   console.log(res, '中心地址')
      //   this.$emit('emitLocation', `${lng},${lat},${res}`)
      // })
    },
    disposeMap() {
      if (this.map == null) return
      this.map.off()
      this.map.remove()
      this.map = null
    },
    getAddress(lng, lat, cb) {
      const params = {
        location: `${lng},${lat}`,
        radius: 1000,
        sdkversion: '1.4.15',
        appname: 'https://lbs.amap.com/api/javascript-api/example/geocoder/regeocoding'
      }
      const url = mapUrl(params)
      fetch(url)
        .then(response => response.json())
        .then(body => {
          console.log(body)
          const address = null
          if (body.regeocode) {
            const result = body.regeocode
            console.log(result, 'result')
            this.address = result.formatted_address
          }
          cb(address)
          this.fetching = false
        })
    },
    clickMap(e) {
      const latlng = e.latlng
      const locationArr = [latlng.lat, latlng.lng]
      this.addMarker(locationArr)
    },
    addMarker(latlng = [29.557156, 106.577026], name = '') {
      this.removeMarker()
      this.$emit('emitLocation', `${latlng[1]},${latlng[0]}`)
      L.marker(latlng, {
        title: name, // 鼠标停留时显示的文字
        riseOnHover: true // 鼠标划过时,Marker切换到最前方
      }).addTo(this.map)
    },
    removeMarker() {
      const that = this
      this.map.eachLayer(function(layer) {
        if (layer instanceof L.Marker) {
          that.map.removeLayer(layer)
        }
      })
    }
  }
}
</script>

<style scoped>
.map-house {
  height: 800px;
}
</style>
