import request from '@/utils/request'

const residentsApi = {
  // get residents info
  residents: '/api/v1/rest/residents',
  findByKeyword: '/api/v1/rest/residents/search/findByKeyword',
  findByHouses_Id: '/api/v1/rest/residents/search/findByHouses_Id',
  findByAllHouses_Id: '/api/v1/rest/residents/search/findAllByHouses_Id',
  add: '/api/v1/resident/register',
  query: '/api/v1/resident/query',
  deleteResidentHouse: '/api/v1/resident/deleteResidentHouse',
  updateResident: '/api/v1/resident/updateHouse',
  addHouseResident: '/api/v1/resident/addHouse'
}

/**
 * list
 * @param param { page: '', size: '', sort: '' }
 * @returns {*}
 */
export function residents(param) {
  return request({
    url: residentsApi.residents + `?page=${param.page}&size=${param.size}&sort=${param.sort}`,
    method: 'get'
  })
}

/**
 * add
 * @param param { id: '', name: '', code: '', area: '' }
 * @returns {*}
 */
export function addResidents(param) {
  return request({
    url: residentsApi.add,
    method: 'post',
    data: param
  })
}

/**
 * edit
 * @param param { id: '', name: '', code: '', area: '' }
 * @returns {*}
 */
export function editResidents(param) {
  return request({
    url: residentsApi.residents + `/${param.id}`,
    method: 'patch',
    data: param
  })
}

/**
 * del
 * @param param { id: '' }
 * @returns {*}
 */
export function delResidents(param) {
  return request({
    url: residentsApi.residents + `/${param.id}`,
    method: 'delete'
  })
}

/**
 * 
 * @param param { id: '' }
 * @returns {*}
 */
export function getResidentHouses(id) {
  return request({
    url: residentsApi.residents + `/${id}/houses`,
    method: 'get'
  })
}

/**
 * 
 * @param param
 * @returns {*}
 */
export function delResidentHouse(param) {
  let url = residentsApi.deleteResidentHouse
  if (param) {
    url += '?' + Object.keys(param).map(item => `${item}=${param[item]}`).join('&')
  }
  return request({
    url,
    method: 'post'
  })
}

/**
 * 
 * @param param { id: '' }
 * @returns {*}
 */
export function postResidentHouse(id, data) {
  return request({
    url: residentsApi.residents + `/${id}/houses`,
    method: 'post',
    headers: {
      'Content-Type': 'text/uri-list'
    },
    data
  })
}

/**
 * 
 * @param param { residentId: '', houseIds: 'id1,id2' }
 * @returns {*}
 */
export function updateResidentHouse(param) {
  let url = residentsApi.updateResident
  if (param) {
    url += '?' + Object.keys(param).map(item => `${item}=${param[item]}`).join('&')
  }
  return request({
    url,
    method: 'get'
  })
}

/**
 * 
 * @param param { residentId: '', houseIds: 'id1,id2' }
 * @returns {*}
 */
export function addResidentHouse(param) {
  let url = residentsApi.addHouseResident
  if (param) {
    url += '?' + Object.keys(param).map(item => `${item}=${param[item]}`).join('&')
  }
  return request({
    url,
    method: 'get'
  })
}

/**
 * search
 * @param param { keyword: '', page: '', size: '', sort: ''  }
 * @returns {*}
 */
export function findResidents(param) {
  let url = residentsApi.findByKeyword
  if (param) {
    url += '?' + Object.keys(param).map(item => `${item}=${param[item]}`).join('&')
  }
  return request({
    url,
    method: 'get'
  })
}

/**
 * search
 * @param param { keyword: '', page: '', size: '', sort: ''  }
 * @returns {*}
 */
export function queryResidents(param) {
  let url = residentsApi.query
  if (param) {
    url += '?' + Object.keys(param).map(item => `${item}=${param[item]}`).join('&')
  }
  return request({
    url,
    method: 'get'
  })
}

/**
 * search
 * @param param { id: '' }
 * @returns {*}
 */
export function findByIdResidents(param) {
  return request({
    url: residentsApi.Residents + `/${param.id}`,
    method: 'get'
  })
}

/**
 * residents
 * @param param { id: '', name: '' }
 * @returns {*}
 */
export function inResidents(param) {
  return request({
    url: residentsApi.residents + `/${param.id}/${param.name}`,
    method: 'get'
  })
}

/**
 * findByHouses_Id
 * @param param { id: '', page: '', size: '', sort: '' }
 * @returns {*}
 */
export function findByHousesIdResident(param) {
  return request({
    url: residentsApi.findByHouses_Id + `?houseId=${param.id}&page=${param.page}&size=${param.size}&sort=${param.sort}`,
    method: 'get',
    headers: {
      'Content-Type': 'application/hal+json'
    }
  })
}

/**
 * findByHouses_Id
 * @param param { id: '', page: '', size: '', sort: '' }
 * @returns {*}
 */
export function findByHousesIdAllResident(id) {
  return request({
    url: residentsApi.findByAllHouses_Id + `?houseId=${id}`,
    method: 'get'
  })
}
