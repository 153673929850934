<template>
  <pro-layout
    :menus="menus"
    :collapsed="collapsed"
    :mediaQuery="query"
    :isMobile="isMobile"
    :handleMediaQuery="handleMediaQuery"
    :handleCollapse="handleCollapse"
    :i18nRender="i18nRender"
    v-bind="settings"
  >
    <ads v-if="isProPreviewSite && !collapsed" />
    <template v-slot:headerContentRender>
      <div v-if="isHouse">
        <a-select :default-value="house.name" style="width: 200%;" @change="handleChange">
          <a-select-option v-for="item in houses" :key="item.id" :value="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
      </div>
    </template>
    <template v-slot:menuHeaderRender>
      <div>
        <logo-svg />
        <h1>{{ title }}</h1>
      </div>
    </template>

    <template v-slot:rightContentRender>
      <right-content :top-menu="settings.layout === 'topmenu'" :is-mobile="isMobile" :theme="settings.theme" />
    </template>
    <template v-slot:footerRender>
      <global-footer />
    </template>
    <router-view />
  </pro-layout>
</template>

<script>
import { updateTheme } from '@ant-design-vue/pro-layout'
import { i18nRender } from '@/locales'
import { mapState } from 'vuex'
import {
  CONTENT_WIDTH_TYPE,
  SIDEBAR_TYPE,
  TOGGLE_MOBILE_TYPE,
  ACCESS_TOKEN,
  APP_HOUSE,
  APP_HOUSEID
} from '@/store/mutation-types'

import defaultSettings from '@/config/defaultSettings'
import RightContent from '@/components/GlobalHeader/RightContent'
import GlobalFooter from '@/components/GlobalFooter'
import Ads from '@/components/Other/CarbonAds'
import LogoSvg from '../assets/logo.svg?inline'
import storage from 'store'
import { findDeveloperHouse } from '@/api/statistics'
import { houseGet } from '@/api/houses'
export default {
  name: 'BasicLayout',
  components: {
    RightContent,
    GlobalFooter,
    LogoSvg,
    Ads
  },
  inject: ['reload'],
  data() {
    return {
      // preview.pro.antdv.com only use.
      isProPreviewSite: process.env.VUE_APP_PREVIEW === 'true' && process.env.NODE_ENV !== 'development',
      // end

      // base
      menus: [],
      // 侧栏收起状态
      collapsed: false,
      title: defaultSettings.title,
      settings: {
        // 布局类型
        layout: defaultSettings.layout, // 'sidemenu', 'topmenu'
        // CONTENT_WIDTH_TYPE
        contentWidth: defaultSettings.layout === 'sidemenu' ? CONTENT_WIDTH_TYPE.Fluid : defaultSettings.contentWidth,
        // 主题 'dark' | 'light'
        theme: defaultSettings.navTheme,
        // 主色调
        primaryColor: defaultSettings.primaryColor,
        fixedHeader: defaultSettings.fixedHeader,
        fixSiderbar: defaultSettings.fixSiderbar,
        colorWeak: defaultSettings.colorWeak,

        hideHintAlert: false,
        hideCopyButton: false
      },
      // 媒体查询
      query: {},

      // 是否手机模式
      isMobile: false,
      isHouse: true
    }
  },
  computed: {
    ...mapState({
      // 动态主路由
      mainMenu: state => state.permission.addRouters
    }),
    developerId() {
      return this.$store.state.user.info.id
    },
    houseId() {
      return this.$store.state.house.houseId
    },
    house() {
      return this.$store.state.house.house
    },
    houses() {
      return this.$store.state.house.houses || []
    },
    serial() {
      return window.location.origin
    }
  },
  created() {
    const routes = this.mainMenu.find(item => item.path === '/')
    this.menus = (routes && routes.children) || []
    // 处理侧栏收起状态
    this.$watch('collapsed', () => {
      this.$store.commit(SIDEBAR_TYPE, this.collapsed)
    })
    this.$watch('isMobile', () => {
      this.$store.commit(TOGGLE_MOBILE_TYPE, this.isMobile)
    })
  },
  watch: {
    $route: 'fetchData'
  },
  mounted() {
    this.fetchData()
    const userAgent = navigator.userAgent
    if (userAgent.indexOf('Edge') > -1) {
      this.$nextTick(() => {
        this.collapsed = !this.collapsed
        setTimeout(() => {
          this.collapsed = !this.collapsed
        }, 16)
      })
    }

    // first update color
    // TIPS: THEME COLOR HANDLER!! PLEASE CHECK THAT!!
    if (process.env.NODE_ENV !== 'production' || process.env.VUE_APP_PREVIEW === 'true') {
      updateTheme(this.settings.primaryColor)
    }
  },
  methods: {
    fetchData() {
      const param = {
        developerId: this.developerId
      }
      findDeveloperHouse(param).then(res1 => {
        const data = res1.data.content
        if (data && data.length > 0) {
          console.log('2222')
          console.log(this.$store.state, 'this.$store.state')
          const newArr = data.map(item => item.id)
          const house = storage.get(APP_HOUSE)
          const saveInfo = () => {
            this.$store.commit('SET_HOUSEID', storage.get(APP_HOUSEID))
            this.$store.commit('SET_HOUSE', storage.get(APP_HOUSE))
            this.$store.commit('SET_HOUSES', data)
          }
          const saveNoneInfo = () => {
            this.$store.commit('SET_HOUSEID', data[0].id)
            this.$store.commit('SET_HOUSE', data)
            storage.set(APP_HOUSE, data[0])
            storage.set(APP_HOUSEID, data[0].id)
            this.$store.commit('SET_HOUSES', data)
          }
          console.log(house, 'househousehousehousehouse')
          if (house === undefined) {
            saveNoneInfo()
          } else {
            if (newArr.includes(house.id)) {
              saveInfo()
            } else {
              this.$notification.warning({
                message: '提示',
                description: `开发商已不是${house.name}的开发商, 以为您默认选择${data[0].name}`
              })
              saveNoneInfo()
            }
          }
          const href = window.location.href
          const judge1 = href.indexOf('/residence/list') !== -1
          const judge2 = href.indexOf('/residence/houses/base') !== -1
          const judge3 = href.indexOf('/broker/finish') !== -1
          const judge4 = href.indexOf('/task/haveRelease') !== -1
          const judge5 = href.indexOf('/risk/house') !== -1
          const validate = judge1 || judge2 || judge3 || judge4 || judge5
          if (validate) {
            this.isHouse = false
          } else {
            this.isHouse = true
          }
        } else {
          this.$notification.warning({
            message: '提示',
            description: '请为开发商绑定楼盘！'
          })
          storage.remove(ACCESS_TOKEN)
        }
      })
    },
    i18nRender,
    handleMediaQuery(val) {
      this.query = val
      if (this.isMobile && !val['screen-xs']) {
        this.isMobile = false
        return
      }
      if (!this.isMobile && val['screen-xs']) {
        this.isMobile = true
        this.collapsed = false
        this.settings.contentWidth = CONTENT_WIDTH_TYPE.Fluid
        // this.settings.fixSiderbar = false
      }
    },
    handleCollapse(val) {
      this.collapsed = val
    },
    handleSettingChange({ type, value }) {
      console.log('type', type, value)
      type && (this.settings[type] = value)
      switch (type) {
        case 'contentWidth':
          this.settings[type] = value
          break
        case 'layout':
          if (value === 'sidemenu') {
            this.settings.contentWidth = CONTENT_WIDTH_TYPE.Fluid
          } else {
            this.settings.fixSiderbar = false
            this.settings.contentWidth = CONTENT_WIDTH_TYPE.Fixed
          }
          break
      }
    },
    handleChange(value) {
      console.log(this.houseId, value, 'value')
      if (this.houseId !== value) {
        houseGet(value).then(res => {
          console.log(res, 'ressss')
          this.$store.commit('SET_HOUSE', res)
          this.$store.commit('SET_HOUSEID', value)
          storage.set(APP_HOUSE, res)
          storage.set(APP_HOUSEID, res.id)
          this.reload()
          this.$notification.success({
            message: '选择楼盘',
            description: '切换楼盘成功！'
          })
        })
      } else {
        this.$notification.warning({
          message: '选择楼盘',
          description: '请勿重复选择！'
        })
      }
    }
  }
}
</script>

<style lang="less">
@import './BasicLayout.less';
</style>
