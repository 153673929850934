<template>
  <div>
    <!-- <a-upload :multiple="true" :file-list="media" :before-upload="beforeUpload" :remove="handleRemove">
      <a-button> <a-icon type="upload" />上传 </a-button>
    </a-upload> -->

    <a-upload-dragger
      name="file"
      :file-list="media"
      :multiple="true"
      :before-upload="beforeUpload"
      @change="handleChange"
    >
      <p class="ant-upload-drag-icon">
        <a-icon type="inbox" />
      </p>
      <p class="ant-upload-text">
        点击或拖拽文件到此区域上传
      </p>
      <p class="ant-upload-hint">
        支持单个或批量上传。
        <!-- 严禁上传公司数据或其他band文件。 -->
      </p>
    </a-upload-dragger>
  </div>
</template>

<script>
export default {
  name: 'AccesoryUpload',
  props: {
    media: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      fileList: []
    }
  },
  methods: {
    handleChange({ fileList }) {
      this.$emit('emitFile', fileList)
    },
    beforeUpload(file) {
      return false
    }
  }
}
</script>

<style lang="less" scoped></style>
