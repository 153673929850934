import storage from 'store'
import { sign } from '@/api/oss'
import { APP_HOST } from '@/store/mutation-types'

const oss = {
  state: {
    host: ''
  },

  mutations: {
    SET_HOST: (state, host) => {
      state.host = host
    }
  },

  actions: {
    Host({ commit }) {
      return new Promise((resolve, reject) => {
        sign().then(res => {
          const host = res.data.host
          storage.set(APP_HOST, host)
          commit('SET_HOST', host)
          resolve(host)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}

export default oss
