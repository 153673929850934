// eslint-disable-next-line
import { UserLayout, BasicLayout } from '@/layouts'
import { bxAnaalyse } from '@/core/icons'

const RouteView = {
  name: 'RouteView',
  render: (h) => h('router-view')
}

export const asyncRouterMap = [

  {
    path: '/',
    name: 'index',
    component: BasicLayout,
    meta: { title: '首页' },
    redirect: '/backstage/cockpit',
    children: [
      // 首页
      {
        path: '/backstage',
        name: 'backstage',
        redirect: '/backstage/cockpit',
        component: RouteView,
        meta: { title: '首页', keepAlive: true, icon: bxAnaalyse, permission: [ 'home' ] },
        children: [
          {
            path: '/backstage/cockpit',
            name: 'Cockpit',
            component: () => import('@/views/backstage/Cockpit'),
            meta: { title: '数据统计', keepAlive: true, permission: [ 'home_dashboard' ] }
          }
          // {
          //   path: '/backstage/workPlace',
          //   name: 'WorkPlace',
          //   component: () => import('@/views/backstage/Workplace'),
          //   meta: { title: '工作台', keepAlive: true, permission: [ 'home_dashboard' ] }
          // }
        ]
      },
      // Exception
      {
        path: '/exception',
        name: 'exception',
        hidden: true,
        component: RouteView,
        redirect: '/exception/403',
        meta: { title: '异常页', icon: 'warning', permission: [ 'exception' ] },
        children: [
          {
            path: '/exception/403',
            name: 'Exception403',
            component: () => import('@/views/exception/403'),
            meta: { title: '403', permission: [ 'exception' ] }
          },
          {
            path: '/exception/404',
            name: 'Exception404',
            component: () => import('@/views/exception/404'),
            meta: { title: '404', permission: [ 'exception' ] }
          },
          {
            path: '/exception/500',
            name: 'Exception500',
            component: () => import('@/views/exception/500'),
            meta: { title: '500', permission: [ 'exception' ] }
          }
        ]
      },
      // residence
      {
        path: '/residence',
        name: 'residence',
        component: RouteView,
        redirect: '/residence/listView',
        meta: { title: '住宅', icon: 'bank', permission: [ 'residence' ] },
        children: [
          {
            path: '/residence/listView',
            name: 'ResidenceListViewWrapper',
            hideChildrenInMenu: true,
            redirect: '/residence/list',
            component: RouteView,
            meta: { title: '楼盘列表', keepAlive: true, permission: [ 'residence_list' ] },
            children: [
              {
                path: '/residence/list',
                name: 'ResidenceListWrapper',
                component: () => import('@/views/residence/housesList'),
                meta: { title: '列表信息', keepAlive: false, permission: [ 'residence_list' ] }
              },
              {
                path: '/residence/houses',
                name: 'housesEdit',
                component: () => import('@/views/residence/houses/Index'),
                meta: { title: '编辑楼盘', hideHeader: true, permission: [ 'residence_edit' ] },
                redirect: '/residence/houses/base',
                hideChildrenInMenu: true,
                children: [
                  {
                    path: '/residence/houses/base',
                    name: 'InfoEdit',
                    component: () => import('@/views/residence/houses/BaseSetting'),
                    meta: { title: '楼盘信息', hidden: true, permission: [ 'residence_edit_info' ] }
                  },
                  {
                    path: '/residence/houses/img',
                    name: 'ImgEdit',
                    component: () => import('@/views/residence/houses/img'),
                    meta: { title: '图片视频', hidden: true, permission: [ 'residence_edit_media' ] }
                  },
                  {
                    path: '/residence/houses/vr',
                    name: 'VREdit',
                    component: () => import('@/views/residence/houses/vr'),
                    meta: { title: 'VR看房', hidden: true, permission: [ 'residence_edit_vr' ] }
                  },
                  {
                    path: '/residence/houses/presell',
                    name: 'Presell',
                    hideChildrenInMenu: true,
                    component: () => import('@/views/residence/houses/presell'),
                    meta: { title: '预售许可证', hidden: true, permission: [ 'residence_edit_license' ] }
                  },
                  {
                    path: '/residence/houses/type',
                    name: 'TypeEdit',
                    component: () => import('@/views/residence/houses/type'),
                    meta: { title: '户型维护', hidden: true, permission: [ 'residence_edit_room' ] }
                  },
                  {
                    path: '/residence/houses/map',
                    name: 'MapEdit',
                    component: () => import('@/views/residence/houses/map'),
                    meta: { title: '地理位置', hidden: true, permission: [ 'residence_edit_geo' ] }
                  },
                  {
                    path: '/residence/houses/dynamic',
                    name: 'DynamicEdit',
                    component: () => import('@/views/residence/houses/dynamic'),
                    meta: { title: '楼盘动态', hidden: true, permission: [ 'residence_edit_news' ] }
                  },
                  {
                    path: '/residence/houses/tag',
                    name: 'TagEdit',
                    component: () => import('@/views/residence/houses/tag'),
                    meta: { title: '标签', hidden: true, permission: [ 'residence_edit_tags' ] }
                  },
                  {
                    path: '/residence/houses/agent',
                    name: 'AgentEdit',
                    component: () => import('@/views/residence/houses/agent'),
                    meta: { title: '代理商', hidden: true, permission: [ 'residence_edit_agent' ] }
                  }
                  // {
                  //   path: '/residence/houses/station',
                  //   name: 'StationEdit',
                  //   component: () => import('@/views/residence/houses/station'),
                  //   meta: { title: '驻场人员', hidden: true, permission: [ 'residence_edit_site' ] }
                  // }
                ]
              }
            ]
          }
          // {
          //   path: '/residence/create',
          //   name: 'ResidenceCreateWrapper',
          //   component: () => import('@/views/residence/create'),
          //   meta: { title: '楼盘创建', keepAlive: true, permission: [ 'residence_add' ] }
          // }
        ]
      },

      // task
      {
        path: '/task',
        name: 'task',
        component: RouteView,
        redirect: '/task/list',
        meta: { title: '任务管理', icon: 'audit', permission: [ 'task' ] },
        children: [
          {
            path: '/task/list',
            name: 'TaskListViewWrapper',
            component: RouteView,
            redirect: '/task/haveRelease',
            hideChildrenInMenu: true,
            meta: { title: '已发布任务', keepAlive: true, permission: [ 'task_list' ] },
            children: [
              {
                path: '/task/haveRelease',
                name: 'TaskTaskHaveReleaseWrapper',
                component: () => import('@/views/task/haveRelease'),
                meta: { title: '已发布任务', keepAlive: false, hiddenHeaderContent: true, permission: [ 'task_list' ] }
              },
              {
                path: '/task/taskInfo',
                name: 'TaskTaskInfoWrapper',
                component: () => import('@/views/task/taskInfo'),
                meta: { title: '任务详情', keepAlive: false, hiddenHeaderContent: true, permission: [ 'task_list' ] }
              }
            ]
          }
          // {
          //   path: '/task/release',
          //   name: 'TaskTaskReleaseWrapper',
          //   component: () => import('@/views/task/release/release'),
          //   meta: { title: '发布任务', keepAlive: false, hiddenHeaderContent: true, permission: [ 'task_list' ] }
          // }
        ]
      },
      // consultant
      {
        path: '/consultant',
        name: 'consultant',
        component: RouteView,
        redirect: '/consultant/list',
        meta: { title: '置业顾问', icon: 'solution', permission: [ 'order' ] },
        children: [
          {
            path: '/consultant/list',
            name: 'consultantListWrapper',
            hideChildrenInMenu: true,
            component: () => import('@/views/consultant/list'),
            meta: { title: '置业顾问列表', keepAlive: true }
          },
          {
            path: '/consultant/ranking/:pageNo([1-9]\\d*)?',
            name: 'ConsultantRankingWrapper',
            hideChildrenInMenu: true,
            component: () => import('@/views/consultant/ranking'),
            meta: { title: '数据排行', keepAlive: true, permission: [ 'order_report' ] }
          }
        ]
      },
      {
        path: '/risk',
        name: 'risk',
        component: RouteView,
        redirect: '/risk/house',
        meta: { title: '风控管理', icon: 'rise' },
        children: [
          {
            path: '/risk/house',
            name: 'riskHouseWrapper',
            hideChildrenInMenu: true,
            component: () => import('@/views/risk/house/index'),
            meta: { title: '图像证据', keepAlive: true }
          },
          {
            path: '/risk/sign',
            name: 'riskSignWrapper',
            hideChildrenInMenu: true,
            component: () => import('@/views/risk/sign/index'),
            meta: { title: '风控预警', keepAlive: true }
          }
        ]
      },
      // order
      {
        path: '/order',
        name: 'order',
        component: RouteView,
        redirect: '/order/sign',
        meta: { title: '销售数据', icon: 'calendar', permission: [ 'order' ] },
        children: [
          {
            path: '/order/sign/:pageNo([1-9]\\d*)?',
            name: 'OrderSignWrapper',
            hideChildrenInMenu: true,
            component: () => import('@/views/order/sign'),
            meta: { title: '认购/签约', keepAlive: true, permission: [ 'order_report' ] }
          }
        ]
      },
      // visit
      {
        path: '/visit',
        name: 'visit',
        component: RouteView,
        redirect: '/visit/report',
        meta: { title: '报备管理', icon: 'calendar', permission: [ 'order' ] },
        children: [
          {
            path: '/visit/report/:pageNo([1-9]\\d*)?',
            name: 'VisitReportWrapper',
            hideChildrenInMenu: true,
            component: () => import('@/views/visit/report'),
            meta: { title: '报备单', keepAlive: true, permission: [ 'order_report' ] }
          }
          // {
          //   path: '/visit/visit/:pageNo([1-9]\\d*)?',
          //   name: 'VisitVisitWrapper',
          //   hideChildrenInMenu: true,
          //   component: () => import('@/views/visit/visit'),
          //   meta: { title: '到访单', keepAlive: true, permission: [ 'order_report' ] }
          // }
        ]
      },
      // commission
      {
        path: '/commission',
        name: 'commission',
        component: RouteView,
        redirect: '/commission/publicity',
        meta: { title: '佣金管理', icon: 'account-book', permission: ['commission'] },
        children: [
          {
            path: '/commission/manage',
            name: 'CommissionManageWrapper',
            hideChildrenInMenu: true,
            component: () => import('@/views/commission/manage'),
            meta: { title: '结佣管理', keepAlive: true, permission: ['commission_audit'] }
          }
        ]
      },
      // client
      {
        path: '/client',
        name: 'client',
        component: RouteView,
        redirect: '/client/manage',
        meta: { title: '老带新管理', icon: 'share-alt', permission: ['commission'] },
        children: [
          {
            path: '/client/manage',
            name: 'ClientManageWrapper',
            hideChildrenInMenu: true,
            component: () => import('@/views/client/manage'),
            meta: { title: '客户管理', keepAlive: true, permission: ['commission'] }
          }
        ]
      },
      // broker
      {
        path: '/broker',
        name: 'broker',
        component: RouteView,
        redirect: '/broker/finish',
        meta: { title: '经纪人管理', icon: 'reddit', permission: [ 'broker' ] },
        children: [
          {
            path: '/broker/finish',
            name: 'BrokerFinishWrapper',
            hideChildrenInMenu: true,
            component: () => import('@/views/broker/finish'),
            meta: { title: '已接任务经纪人', keepAlive: true, permission: [ 'broker_finish' ] }
          }
        ]
      }

      // auth
      // {
      //   path: '/authManage',
      //   name: 'auth',
      //   component: RouteView,
      //   redirect: '/authManage/user',
      //   meta: { title: '权限管理', icon: 'key', permission: [ 'auth' ] },
      //   children: [
      //     {
      //       path: '/authManage/user',
      //       name: 'authManageUserWrapper',
      //       hideChildrenInMenu: true,
      //       component: () => import('@/views/authManage/user'),
      //       meta: { title: '后台用户', keepAlive: true, permission: [ 'auth_user' ] }
      //     },
      //     {
      //       path: '/authManage/role',
      //       name: 'authManageRoleWrapper',
      //       hideChildrenInMenu: true,
      //       component: () => import('@/views/authManage/role'),
      //       meta: { title: '角色管理', keepAlive: true, permission: [ 'auth_role' ] }
      //     },
      //     {
      //       path: '/authManage/auth',
      //       name: 'authManageAuthWrapper',
      //       hideChildrenInMenu: true,
      //       component: () => import('@/views/authManage/auth'),
      //       meta: { title: '权限设置', keepAlive: true, permission: [ 'auth_set' ] }
      //     },
      //     {
      //       path: '/authManage/station',
      //       name: 'authManageStationWrapper',
      //       hideChildrenInMenu: true,
      //       component: () => import('@/views/authManage/station'),
      //       meta: { title: '驻场管理', keepAlive: true, permission: [ 'auth_station' ] }
      //     }
      //   ]
      // }
    ]
  },
  {
    path: '*', redirect: '/404', hidden: true
  }
]

/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: '/user',
    component: UserLayout,
    redirect: '/user/login',
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'login',
        component: () => import('@/views/user/Login')
      },
      {
        path: 'register',
        name: 'register',
        component: () => import('@/views/user/Register')
      },
      {
        path: 'register-result',
        name: 'registerResult',
        component: () => import('@/views/user/RegisterResult')
      },
      {
        path: 'recover',
        name: 'recover',
        component: undefined
      }
    ]
  },

  {
    path: '/404',
    component: () => import('@/views/exception/404')
  }

]
