const env = {
    state: {
        serial: ''
    },
    mutations: {
        SET_SERIAL (state, data) {
            state.serial = data
        }
    },
    action: {}
}
export default env
