import request from '@/utils/request'

const addrsApi = {
  // get addr info
  addrs: '/api/v1/addrs',
  open: '/api/v1//addr/open',
  query: '/api/v1//addr/query'
}

/**
 * all
 * @param { page: 0, size: 20, sort: 'createTime,desc' }
 */
export function addrs(param) {
  let url = addrsApi.addrs;
  if (param) {
    url += '?' + Object.keys(param).map(m => `${m}=${param[m]}`).join('&')
  }
  return request({
    url,
    method: 'get'
  })
}

/**
 * all
 * @param { page: 0, size: 20, sort: 'createTime,desc' }
 */
export function addAddr(data) {
  const url = addrsApi.addrs;
  return request({
    url,
    method: 'post',
    data
  })
}

/**
 * edit
 * @param param { id: '', name: '', code: '', area: '' }
 * @returns {*}
 */
export function editAddr(id, data) {
  return request({
    url: addrsApi.addrs + `/${id}`,
    method: 'patch',
    data
  })
}

/**
 * del
 * @param param id
 * @returns {*}
 */
export function delAddr(id) {
  return request({
    url: addrsApi.addrs + `/${id}`,
    method: 'delete'
  })
}

/**
 * func
 * @param 
 * @returns {*}
 */
export function open() {
  return request({
    url: addrsApi.open,
    method: 'get'
  })
}

/**
 * func
 * @param {
 * list: [50, 51], // 需要开通或关闭的城市ID,多个逗号分隔 例: http://11.0.2.101:8911/addr/open?id=50&id=51&open=true
 * open: true, // 是否开通：true 或者 false Default value : true
 * }
 * @returns {*}
 */
export function setOpen(list = [], open = false) {

  let url = addrsApi.open
  if (list) {
    url += '?' + list.map(m => `id=${m}`).join('&')
    url += '&open=' + open
  }
  return request({
    url: url,
    method: 'put'
  })
}

/**
 * func
 * @param {
 * level: province, // 级别：省=province,市=city,县=district
 * opended: true, // boolean 是否已经开通
 * parentId: 50 // 父级地址ID
 * }
 * @returns {*}
 */
export function queryAddr(param) {
  let url = addrsApi.query
  if (param) {
    url += '?' + Object.keys(param).map(m => `${m}=${param[m]}`).join('&')
  }
  return request({
    url: url,
    method: 'get'
  })
}
