const house = {
    state: {
        houseId: '',
        house: null,
        houses: []
    },
    mutations: {
        SET_HOUSEID (state, data) {
            state.houseId = data
        },
        SET_HOUSE (state, data) {
            state.house = data
        },
        SET_HOUSES (state, data) {
            state.houses = data
        }
    },
    action: {}
}
export default house
