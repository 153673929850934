import storage from 'store'
import { login, getInfo, logout } from '@/api/login'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import { welcome } from '@/utils/util'

const user = {
  state: {
    token: '',
    name: '',
    welcome: '',
    avatar: '',
    roles: [],
    info: {}
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, { name, welcome }) => {
      state.name = name
      state.welcome = welcome
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_ROLES: (state, roles) => {
      state.roles = roles
    },
    SET_INFO: (state, info) => {
      state.info = info
    }
  },

  actions: {
    // 登录
    Login ({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo).then(response => {

          const result = response.data
          storage.set(ACCESS_TOKEN, result.token, result.expiredIn * 1000)
          commit('SET_TOKEN', result.token)

          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 获取用户信息
    GetInfo ({ commit }) {
      return new Promise((resolve, reject) => {
        getInfo().then(response => {
          const data = response
          console.log(data, 'data ');
          const result = data.data
          result.roles = [
            {
              description: '超级管理员，拥有所有权限',
              id: 'admin',
              name: '超级管理员',
              permissions: 'auth,auth_role,auth_set,auth_station,auth_user,broker,broker_finish,broker_invite_add,broker_invite_unused,broker_invite_used,broker_list,broker_unfinish,commission,commission_announce,commission_audit,commission_payment,developer,developer_add,developer_list,get-users,home,home_dashboard,order,order_report,order_sign,residence,residence_add,residence_edit,residence_edit_geo,residence_edit_info,residence_edit_license,residence_edit_media,residence_edit_news,residence_edit_room,residence_edit_site,residence_edit_tags,residence_edit_vr,residence_list,residence_site,residence_edit_agent,system,system_city,system_config,system_copyright,system_dict,system_log,task,task_add,task_audit,task_list,test,user,user_broker_feedback,user_list,user_site_feedback',
              sys: true
            }
          ]
          result.permissions = result.roles[0].permissions
          if (result.roles && result.roles.length > 0) {
            // const role = result.role
            // role.permissions = result.role.permissions
            // role.permissions.map(per => {
            //   if (per.actionEntitySet != null && per.actionEntitySet.length > 0) {
            //     const action = per.actionEntitySet.map(action => { return action.action })
            //     per.actionList = action
            //   }
            // })
            // role.permissionList = role.permissions.map(permission => { return permission.permissionId })
            result.roles = result.roles.map(i=>{
              i.permissionList = i.permissions ? i.permissions.split(',') : []; 
              return i;
            })
            result.permissionList = result.permissions.split(',');
            commit('SET_ROLES', result.roles)
            commit('SET_INFO', result)

            commit('SET_NAME', { name: result.name, welcome: welcome() })
            commit('SET_AVATAR', result.avatar)
  
            resolve(result)
          } else {
            reject(new Error('getInfo: roles must be a non-null array !'))
          }
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 登出
    Logout ({ commit, state }) {
      return new Promise((resolve) => {
        logout(state.token).then(() => {
          commit('SET_TOKEN', '')
          commit('SET_ROLES', [])
          storage.remove(ACCESS_TOKEN)
          resolve()
        }).catch(() => {
          resolve()
        }).finally(() => {
        })
      })
    }

  }
}

export default user
