import request from '@/utils/request'

const housesApi = {
  // get houses info
  base: '/api/v1',
  houses: '/api/v1/rest/houses',
  findByKeyword: '/api/v1/rest/houses/search/findByKeyword',
  query: '/api/v1/house/query'
}

/**
 * list
 * @param param { page: '', size: '', sort: '' }
 * @returns {*}
 */
export function houses (param) {
  param = { ...param, ...{ projection: 'withAll' } }
  let url = housesApi.houses
  if (param) {
    url += '?' + Object.keys(param).map(item => `${item}=${param[item]}`).join('&')
  }
  return request({
    url,
    method: 'get'
  })
}

/**
 * list
 * @param param { developerName: '', keyword: '', residentId: '', page: '', size: '', sort: '' }
 * @returns {*}
 */
export function queryHouses (param) {
  let url = housesApi.query
  if (param) {
    url += '?' + Object.keys(param).map(item => `${item}=${param[item]}`).join('&')
  }
  return request({
    url,
    method: 'get'
  })
}

/**
 * all
 * @param
 * @returns {*}
 */
export function housesAll () {
  return request({
    url: housesApi.houses,
    method: 'get'
  })
}

/**
 * add
 * @param param { name: '', developer: '', area: '', type: '' , time }
 * @returns {*}
 */
export function addHouses (param) {
  return request({
    url: housesApi.houses,
    method: 'post',
    data: param
  })
}

/**
 * get
 * @param id
 * @returns {*}
 */
export function houseGet (id) {
  return request({
    url: housesApi.houses + `/${id}`,
    method: 'get'
  })
}

/**
 * edit
 * @param param { id: '', name: '', code: '', area: '' }
 * @returns {*}
 */
export function editHouses (id, data) {
  return request({
    url: housesApi.houses + `/${id}`,
    method: 'patch',
    data
  })
}

/**
 * del
 * @param param { id: '' }
 * @returns {*}
 */
export function delHouses (param) {
  return request({
    url: housesApi.houses + `/${param.id}`,
    method: 'delete'
  })
}

/**
 * search
 * @param param id
 * @returns {*}
 */
export function findByIdHouse (id) {
  return request({
    url: housesApi.houses + `/${id}?projection=withAll`,
    method: 'get'
  })
}

/**
 * search
 * @param param { id: ''}
 * @returns {*}
 */
export function saveHouse (param) {
  return request({
    url: housesApi.houses + `/${param.id}`,
    method: 'patch',
    data: param
  })
}

/**
 * search
 * @param param { keyword: '', page: '', size: '', sort: ''  }
 * @returns {*}
 */
export function findHouses (param) {
  return request({
    url: housesApi.findByKeyword + `?keyword=${param.keyword}&page=${param.page}&size=${param.size}&sort=${param.sort}`,
    method: 'get'
  })
}

/**
 * houses
 * @param param { houseId: '', requestName: '', requestId: '', method: '' }
 * @returns {*}
 */
export function inHouses (param) {
  return request({
    url: housesApi.houses + `/${param.houseId}/${param.requestName}/${param.requestId}`,
    method: param.method
  })
}

/**
 * houses
 * @param method { method: '' }
 * @param param { houseId: '', requestName: '' }
 * @returns {*}
 */
export function houseSub (method, param) {
  return request({
    url: housesApi.houses + `/${param.houseId}/${param.requestName}`,
    method: method,
    data: param.data
  })
}

/**
 * houses
 * @param method { method: 'get' }
 * @param param { houseId: '', requestName: '' }
 * @returns {*}
 */
export function getHouseSub (houseId, name) {
  return request({
    url: housesApi.houses + `/${houseId}/${name}`,
    method: 'get'
  })
}

/**
 * houses
 * @param method { method: 'put' }
 * @param param { houseId: '', requestName: '' }
 * @returns {*}
 */
export function putHouseSub (houseId, name, data) {
  return request({
    url: housesApi.houses + `/${houseId}/${name}`,
    method: 'put',
    data
  })
}

/**
 * houses
 * @param 
 * @param data
 * @returns {*}
 */
export function putHouseResident (houseId, data) {
  return request({
    url: housesApi.houses + `/${houseId}/residents`,
    method: 'put',
    headers: {
      'Content-Type': 'text/uri-list'
    },
    data
  })
}

/**
 * houses
 * @param param { houseId: '', requestName: '', method: '', data: `${}` }
 * @returns {*}
 */
export function bindHouse (param) {
  return request({
    url: housesApi.houses + `/${param.houseId}/${param.requestName}`,
    method: param.method,
    headers: {
      'Content-Type': 'text/uri-list'
    },
    data: param.data
  })
}

/**
 * residents
 * @param param { houseId: '', requestName: '', requestId: '' }
 * @returns {*}
 */
export function delHouseSub (param) {
  return request({
    url: housesApi.houses + `/${param.houseId}/${param.requestName}/${param.requestId}`,
    method: 'delete'
  })
}
