<template>
  <div :class="wrpCls">
    <a-tooltip placement="bottom">
      <template slot="title">
        <span>
          <img :src="qrcodeImg ? qrcodeImg : errorImg" class="myImg" alt="老带新二维码" slot="cover" />
        </span>
      </template>
      <a-button type="primary" icon="scan">老带新二维码</a-button>
      <span class="qrcodeBtn"></span>
    </a-tooltip>
    <avatar-dropdown :menu="showMenu" :current-user="currentUser" :class="prefixCls" />
  </div>
</template>

<script>
import errorImg from '@/assets/img/errorImg.png'
import AvatarDropdown from './AvatarDropdown'
import SelectLang from '@/components/SelectLang'

export default {
  name: 'RightContent',
  components: {
    AvatarDropdown,
    SelectLang
  },
  props: {
    prefixCls: {
      type: String,
      default: 'ant-pro-global-header-index-action'
    },
    isMobile: {
      type: Boolean,
      default: () => false
    },
    topMenu: {
      type: Boolean,
      required: true
    },
    theme: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      generateQcode: '/api/v1/developer/generateQcode',
      errorImg,
      qrcodeImg: null,
      showMenu: true,
      currentUser: {}
    }
  },
  computed: {
    wrpCls () {
      return {
        'ant-pro-global-header-index-right': true,
        [`ant-pro-global-header-index-${(this.isMobile || !this.topMenu) ? 'light' : this.theme}`]: true
      }
    },
    user () {
      return this.$store.state.user.info
    },
    developerId() {
      return this.$store.state.user.info.id
    },
    serial() {
      return window.location.origin
    }
  },
  mounted () {
    this.codeCreate()
    setTimeout(() => {
      this.currentUser = {
        name: this.user.name
      }
    }, 1500)
  },
  methods: {
    codeCreate() {
      const { developerId } = this
      const param = {}
      if (developerId) {
        Object.assign(param, { developerId })
        this.qrcodeImg = this.requestFormat(this.serial + this.generateQcode, param)
      } else {
        this.$notification.error({
          message: this.noticeTitle,
          description: '没有开发商信息！'
        })
      }
    }
  }
}
</script>
